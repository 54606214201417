import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
  type?: 'filled' | 'outlined' | 'default';
  height?: number;
};

function Check({
  color = theme.colors.black.dark,
  height = 20,
  type = 'default',
  ...props
}: Props) {
  if (type === 'filled') {
    return (
      <svg
        fill="none"
        height={height}
        viewBox="0 0 20 20"
        width="20"
        {...props}
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
          fill={color}
        />
      </svg>
    );
  }
  if (type === 'outlined') {
    return (
      <svg
        fill="none"
        height={height}
        viewBox="0 0 20 20"
        width="20"
        {...props}
      >
        <path
          d="M15.2975 6.29251C14.9072 5.89941 14.2717 5.89829 13.88 6.29L8 12.17L5.11549 9.29352C4.7257 8.90482 4.09474 8.90526 3.70549 9.29451C3.31586 9.68414 3.31586 10.3159 3.70549 10.7055L7.54545 14.5455C7.79649 14.7965 8.20351 14.7965 8.45455 14.5455L15.295 7.705C15.6848 7.31524 15.6859 6.68365 15.2975 6.29251ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
          fill={color}
        />
      </svg>
    );
  }
  return (
    <svg
      fill="none"
      height={height || 13}
      viewBox="0 0 18 13"
      width="18"
      {...props}
    >
      <path
        d="M5.99989 10.17L2.52989 6.70001C2.13989 6.31001 1.50989 6.31001 1.11989 6.70001C0.729893 7.09001 0.729893 7.72001 1.11989 8.11001L5.29989 12.29C5.68989 12.68 6.31989 12.68 6.70989 12.29L17.2899 1.71001C17.6799 1.32001 17.6799 0.690007 17.2899 0.300007C16.8999 -0.0899927 16.2699 -0.0899927 15.8799 0.300007L5.99989 10.17Z"
        fill={color}
      />
    </svg>
  );
}

export default Check;
