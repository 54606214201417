import styled from '@emotion/styled';

const Header = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  width: '100%',
});

export default Header;
