import styled from '@emotion/styled';

const PlayButtonContainer = styled('div')(({ theme }) => ({
  button: {
    backgroundColor: theme.colors.gray['600'],
    borderColor: theme.colors.gray['600'],
    height: '3rem',
    position: 'relative',
    width: '3rem',
    zIndex: 1,
  },
  marginTop: '1rem',
}));

export default PlayButtonContainer;
