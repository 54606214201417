import BareButton from 'primitives/Buttons/BareButton';
import styled from '@emotion/styled';

const ExpandIcon = styled(BareButton)(({ theme }) => ({
  svg: {
    height: '1.1rem',
    width: '3rem',

    path: {
      fill: theme.colors.gray['400'],
    },
  },

  '&:hover': {
    'svg > path': {
      fill: theme.colors.gray['600'],
    },
  },
}));

export default ExpandIcon;
