import BareButton from 'primitives/Buttons/BareButton';
import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';

import styled from '@emotion/styled';

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontWeight: theme.fonts.weight.bold,
  marginBottom: '1rem',
  marginTop: '2.8rem',
  width: '100%',
  justifyContent: 'start',
  paddingLeft: '5rem',

  [mediaQueryBuilder(mediaQueries.max.width['599'])]: {
    paddingLeft: '0',
    justifyContent: 'center',
  },

  svg: {
    fill: theme.colors.gray['600'],
  },

  [BareButton.toString()]: {
    alignItems: 'center',
    color: theme.colors.gray['600'],
  },
}));

export default Actions;
