import BareButton from 'primitives/Buttons/BareButton';
import Body2 from 'primitives/Typography/BodyCopy/Body2';
import styled from '@emotion/styled';

type Props = {
  isPlayed: boolean;
};

const MarkAsPlayed = styled(BareButton)<Props>(
  ({ isPlayed = false, theme }) => ({
    color: isPlayed ? theme.colors.green['600'] : theme.colors.gray['600'],
    [Body2.toString()]: {
      color: isPlayed ? theme.colors.green['600'] : theme.colors.gray['600'],
    },
    'svg > path': {
      fill: isPlayed ? theme.colors.green['600'] : theme.colors.gray['500'],
    },
  }),
);

export default MarkAsPlayed;
