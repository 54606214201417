import Actions from './primitives/Actions';
import analytics, { Events } from 'modules/Analytics';
import BareButton from 'primitives/Buttons/BareButton';
import Body1 from 'primitives/Typography/BodyCopy/Body1';
import Body2 from 'primitives/Typography/BodyCopy/Body2';
import Bullet from 'components/Bullet';
import Caption from 'primitives/Typography/BodyCopy/Caption';
import Card from './primitives/Card';
import Check from 'styles/icons/Check';
import Content from './primitives/Content';
import Description from './primitives/Description';
import ExpandDown from 'styles/icons/ExpandDown';
import ExpandIcon from './primitives/ExpandIcon';
import ExpandUp from 'styles/icons/ExpandUp';
import Header from './primitives/Header';
import HeaderText from './primitives/HeaderText';
import MarkAsPlayed from './primitives/MarkAsPlayed';
import Menu from 'components/Tooltip/Menu';
import NavLink from 'components/NavLink';
import NewEpisodeBadge from 'components/NewEpisodeBadge/NewEpisodeBadge';
import PlayButton from 'components/Player/PlayButtonContainer';
import PlayButtonContainer from './primitives/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayheadPosition from 'components/Tooltip/PlayheadPosition';
import PodcastTranscription from 'styles/icons/PodcastTranscription';
import ProgressBar from './primitives/ProgressBar';
import Share from 'styles/icons/Share';
import ShouldShow from 'components/ShouldShow';
import Title from './primitives/Title';
import Truncate from 'components/Truncate';
import usePlayerActions from 'components/Player/PlayerActions/usePlayerActions';
import usePlayerTime from 'contexts/PlayerTime/usePlayerTime';
import useTrackers from 'contexts/Trackers/hooks/useTrackers';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { ConnectedModals } from 'state/UI/constants';
import { episodeProgress, getPodcastEpisodeUrl } from 'state/Podcast/helpers';
import { formatDate, formatEpisodeDuration } from 'utils/time/dates';
import { get } from 'lodash-es';
import { navigate } from 'state/Routing/actions';
import { openModal } from 'state/UI/actions';
import {
  PlaybackTypeValue,
  STATION_TYPE,
  StationType,
} from 'constants/stationTypes';
import { PlayerState } from 'state/Playback/types/PlayerState';
import { updatePodcastPlayProgress } from 'state/Podcast/actions';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';

const EPISODE_COUNT = 1;

type Props = {
  alwaysOpen?: boolean;
  completed?: boolean;
  currentlyPlaying: {
    mediaId: number;
    playedFrom: string;
    seedId: number | string;
    stationTrackId: number;
    stationType: StationType;
  };
  description: string;
  episodeDuration: number;
  id: number;
  isInitiallyExpanded?: boolean;
  isNewEpisode?: boolean;
  pageName?: string;
  playingState: PlayerState;
  playNext: () => void;
  podcastId: number | string;
  podcastSlug: string;
  position?: number;
  secondsPlayed: number | null | undefined;
  showPodcastTranscriptions?: boolean;
  startDate: number;
  title: string;
  onClick?: () => void;
};

function PodcastCard({
  alwaysOpen = false,
  completed = false,
  currentlyPlaying,
  description,
  episodeDuration,
  id: episodeId,
  isNewEpisode,
  isInitiallyExpanded = false,
  pageName,
  playingState,
  playNext,
  podcastId,
  podcastSlug,
  position,
  secondsPlayed,
  showPodcastTranscriptions,
  startDate,
  title,
  onClick,
}: Props) {
  const dispatch = useDispatch();
  const time = usePlayerTime();
  const playerActions = usePlayerActions();

  const trackers = useTrackers();
  const translate = useTranslate();
  const [isExpanded, setIsExpanded] = useState<boolean>(
    alwaysOpen || isInitiallyExpanded,
  );

  const episodeUrl = getPodcastEpisodeUrl(
    podcastId,
    podcastSlug,
    episodeId,
    title,
  );
  const descriptionLimit = alwaysOpen ? Infinity : 435;
  const playedText =
    completed ? translate('Played') : translate('Mark as Played');
  const isCurrentlyPlaying =
    get(currentlyPlaying, 'stationTrackId') === episodeId;

  let playProgress = time.position;

  if (!isCurrentlyPlaying) {
    if (completed) playProgress = episodeDuration;
    else playProgress = Number(secondsPlayed);
  }

  function markAsPlayed() {
    if (isCurrentlyPlaying && playingState === 'PLAYING') playNext();
    // update after playNext so that player does not interfere with updating progress
    dispatch(
      updatePodcastPlayProgress(
        !completed ? episodeDuration : 0,
        podcastId,
        episodeId,
        !completed,
      ),
    );
  }

  const shareEpisode = async () => {
    trackers.track(Events.Share, {
      seedType: STATION_TYPE.PODCAST,
      seedId: episodeId,
      stationName: title,
    });

    dispatch(
      openModal({
        id: ConnectedModals.Share,
        context: {
          seedType: STATION_TYPE.PODCAST,
          seedId: episodeId,
          url: episodeUrl,
          stationName: title,
          hideDescription: false,
          description,
          dimensions: WIDGET_DIMENSIONS.PODCAST_PROFILE,
        },
      }),
    );
  };

  const descriptionLinks: string = description?.replace(
    /<a href?/g,
    '<a target="_blank" href',
  );
  return (
    <Card data-test="podcast-episode-card">
      <Header>
        <PlayButtonContainer>
          <PlayButton
            currentlyPlaying={currentlyPlaying}
            deferPlay={false}
            key={episodeId}
            playedFrom={PLAYED_FROM.PROF_EPISODE_PLAY}
            playingState={playingState}
            position={position}
            seedId={episodeId}
            stationId={podcastId}
            stationType={STATION_TYPE.PODCAST as PlaybackTypeValue}
            trackId={episodeId}
            trackName={title}
          />
        </PlayButtonContainer>

        <HeaderText
          data-test="podcast-episode-name"
          hasExpandButton={!alwaysOpen}
        >
          <Body1>
            <NavLink to={episodeUrl}>
              <Title currentlyPlaying={isCurrentlyPlaying}>
                <Truncate lines={isExpanded ? 2 : 1}>{title}</Truncate>
              </Title>
            </NavLink>
          </Body1>

          <span>
            <Caption>
              {isNewEpisode && (
                <NewEpisodeBadge
                  css={{ width: '4.5rem' }}
                  hideCount
                  newEpisodeCount={EPISODE_COUNT}
                />
              )}
              {formatDate(startDate, translate)}{' '}
              <ShouldShow shouldShow={!!episodeDuration}>
                <Bullet /> {formatEpisodeDuration(episodeDuration)}
              </ShouldShow>
            </Caption>

            <ShouldShow shouldShow={completed}>
              <Caption>
                <Bullet /> {translate('Played')}
              </Caption>
            </ShouldShow>
          </span>
        </HeaderText>

        <ShouldShow shouldShow={!alwaysOpen}>
          <ExpandIcon onClick={() => setIsExpanded(!isExpanded)}>
            <ShouldShow hiddenElement={<ExpandDown />} shouldShow={isExpanded}>
              <ExpandUp />
            </ShouldShow>
          </ExpandIcon>
        </ShouldShow>
      </Header>

      <Content alwaysOpen={alwaysOpen} isExpanded={isExpanded}>
        <Description
          isHtml
          itemProp="podcastDescription"
          maxChar={descriptionLimit}
          onClick={() => dispatch(navigate({ path: episodeUrl }))}
          text={descriptionLinks}
        />
      </Content>
      <Actions>
        <Menu
          target={
            <BareButton data-test="share-button" underline>
              <Share height="15" />
              <Body2>{translate('Share')}</Body2>
            </BareButton>
          }
        >
          <Menu.List>
            <Menu.Item
              onClick={() => {
                shareEpisode();
              }}
            >
              {translate('Share Episode')}
            </Menu.Item>
            {isCurrentlyPlaying && (
              <Menu.Item
                onClick={() => {
                  playerActions.share(true);
                }}
              >
                {translate('Share from')} <PlayheadPosition />
              </Menu.Item>
            )}
          </Menu.List>
        </Menu>
        <MarkAsPlayed
          data-test="podcast-mark-as-played"
          isPlayed={completed}
          onClick={markAsPlayed}
          underline
        >
          <Check height={15} type="outlined" />
          <Body2>{playedText}</Body2>
        </MarkAsPlayed>
        {showPodcastTranscriptions && (
          <BareButton
            css={{ paddingLeft: '0.8rem' }}
            onClick={() => {
              analytics.trackClick?.(
                `${pageName}|podcast_card|transcription_icon`,
              );
              onClick?.();
            }}
            underline
          >
            <PodcastTranscription height={18} width={18} />
            <NavLink to={onClick ? undefined : `${episodeUrl}#transcription`}>
              <Body2 css={{ paddingLeft: '0.4rem' }}>
                {translate('Transcript')}
              </Body2>
            </NavLink>
          </BareButton>
        )}
      </Actions>

      <ProgressBar
        data-test="podcast-play-progress"
        progress={episodeProgress(playProgress, episodeDuration)}
      />
    </Card>
  );
}

export default PodcastCard;
