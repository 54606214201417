import styled from '@emotion/styled';

type Props = {
  progress: number;
};

const ProgressBar = styled('div')<Props>(({ progress = 0, theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  borderBottomLeftRadius: '0.6rem',
  borderBottomRightRadius: '0.6rem',
  bottom: 0,
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)', // this is Elevation 4
  height: '0.3rem',
  left: 0,
  position: 'absolute',
  width: '100%',

  ...(typeof progress === 'number' && progress > 0 ?
    {
      backgroundColor: theme.colors.gray['300'],

      '&:after': {
        backgroundColor: theme.colors.red.primary,
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: `${progress * 100}%`,
      },
    }
  : {}),
}));

export default ProgressBar;
