import styled from '@emotion/styled';

type Props = {
  currentlyPlaying: boolean;
};

const Title = styled('h3')<Props>(({ currentlyPlaying, theme }) => ({
  color: currentlyPlaying ? theme.colors.red['600'] : 'auto',
  fontSize: theme.fonts.size['16'],
  fontWeight: theme.fonts.weight.regular,
  letterSpacing: 0,
  lineHeight: theme.fonts.lineHeight['26'],
  textAlign: 'left',
}));

export default Title;
