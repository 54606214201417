import Bullet from 'components/Bullet/BulletStyles';
import Caption from 'primitives/Typography/BodyCopy/Caption';
import styled from '@emotion/styled';

type Props = {
  hasExpandButton?: boolean;
};

const PLAY_BUTTON_WIDTH = '3rem';

const HeaderText = styled('div')<Props>(({ hasExpandButton = true, theme }) => {
  const expandIconWidth = hasExpandButton ? '3rem' : '0rem';
  const padding = hasExpandButton ? '4rem' : '2rem';

  return {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100% - ${PLAY_BUTTON_WIDTH} - ${expandIconWidth} - ${padding})`,

    'span:nth-of-type(2)': {
      display: 'flex',
    },

    [Bullet.toString()]: {
      color: theme.colors.gray['400'],
      fontSize: theme.fonts.size['12'],
      padding: '0 0.3rem',
    },

    [Caption.toString()]: {
      '&:first-of-type': {
        color: theme.colors.gray['400'],
      },

      '&:nth-of-type(2)': {
        color: theme.colors.green['600'],
        display: 'flex',
      },
    },
  };
});

export default HeaderText;
