import ExpandableText from 'components/ExpandableText';
import styled from '@emotion/styled';

const Description = styled(ExpandableText)(({ theme }) => ({
  color: theme.colors.gray['500'],
  fontSize: theme.fonts.size['14'],
  fontWeight: theme.fonts.weight.regular,
  letterSpacing: 0,
  lineHeight: theme.fonts.lineHeight['20'],
  marginTop: '1rem',

  'a, button': {
    color: theme.colors.gray['600'],
    fontWeight: theme.fonts.weight.bold,
  },
}));

export default Description;
