import styled from '@emotion/styled';

const Card = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.colors.white.primary,
  borderRadius: '0.3rem',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)', // this is Elevation 4
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '1.3rem',
  overflow: 'hidden',
  padding: '1.5rem',
  position: 'relative',

  svg: {
    cursor: 'pointer',
  },
}));

export default Card;
