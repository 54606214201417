type Props = {
  'data-test'?: string;
  height?: number;
  width?: number;
};

function PodcastTranscription({ height = 24, width = 24, ...props }: Props) {
  return (
    <svg
      {...props}
      fill="none"
      height={height}
      viewBox="0 0 18 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.38636 8.25C8.38636 7.83579 8.72215 7.5 9.13636 7.5H15.0002C15.4144 7.5 15.7502 7.83579 15.7502 8.25C15.7502 8.66421 15.4144 9 15.0002 9H9.13636C8.72215 9 8.38636 8.66421 8.38636 8.25ZM8.38636 11.25C8.38636 10.8358 8.72215 10.5 9.13636 10.5H15.0002C15.4144 10.5 15.7502 10.8358 15.7502 11.25C15.7502 11.6642 15.4144 12 15.0002 12H9.13636C8.72215 12 8.38636 11.6642 8.38636 11.25ZM5.30127 13.5C4.88706 13.5 4.55127 13.8358 4.55127 14.25C4.55127 14.6642 4.88706 15 5.30127 15H14.9997C15.414 15 15.7497 14.6642 15.7497 14.25C15.7497 13.8358 15.414 13.5 14.9997 13.5H5.30127Z"
        fill="#27292D"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.84288 3.34375L9.49461 4.99097H6.95644L5.54653 11.6252H3.74414L5.15405 4.99097H2.6167L2.96497 3.34375H9.84288Z"
        fill="#27292D"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default PodcastTranscription;
